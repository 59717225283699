import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'bilty',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'createnewbilty',
    loadChildren: () =>
      import('./createnewbilty/createnewbilty.module').then(
        (m) => m.CreatenewbiltyPageModule
      ),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'statusmodal',
    loadChildren: () =>
      import('./statusmodal/statusmodal.module').then(
        (m) => m.StatusmodalPageModule
      ),
  },
  {
    path: 'invoice',
    loadChildren: () =>
      import('./invoice/invoice.module').then((m) => m.InvoicePageModule),
  },
  {
    path: 'createinvoice',
    loadChildren: () =>
      import('./createinvoice/createinvoice.module').then(
        (m) => m.CreateinvoicePageModule
      ),
  },
  {
    path: 'ledger',
    loadChildren: () =>
      import('./ledger/ledger.module').then((m) => m.LedgerPageModule),
  },
  {
    path: 'addpartyreceivedpayment',
    loadChildren: () =>
      import('./addpartyreceivedpayment/addpartyreceivedpayment.module').then(
        (m) => m.AddpartyreceivedpaymentPageModule
      ),
  },
  {
    path: 'edit-builty/:id',
    loadChildren: () =>
      import('./edit-builty/edit-builty.module').then(
        (m) => m.EditBuiltyPageModule
      ),
  },
  {
    path: 'viewoption',
    loadChildren: () => import('./viewoption/viewoption.module').then( m => m.ViewoptionPageModule)
  },
  {
    path: 'driver',
    loadChildren: () => import('./driver/driver.module').then( m => m.DriverPageModule)
  },
  {
    path: 'vehicle',
    loadChildren: () => import('./vehicle/vehicle.module').then( m => m.VehiclePageModule)
  },
  {
    path: 'consignor',
    loadChildren: () => import('./consignor/consignor.module').then( m => m.ConsignorPageModule)
  },
  {
    path: 'consignee',
    loadChildren: () => import('./consignee/consignee.module').then( m => m.ConsigneePageModule)
  },
  {
    path: 'country-select',
    loadChildren: () => import('./country-select/country-select.module').then( m => m.CountrySelectPageModule)
  },
  {
    path: 'edit-invoice/:id',
    loadChildren: () => import('./edit-invoice/edit-invoice.module').then( m => m.EditInvoicePageModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },  {
    path: 'analytics',
    loadChildren: () => import('./analytics/analytics.module').then( m => m.AnalyticsPageModule)
  },
  {
    path: 'subscription-history',
    loadChildren: () => import('./subscription-history/subscription-history.module').then( m => m.SubscriptionHistoryPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
