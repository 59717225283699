<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <!-- <ion-list-header>Inbox</ion-list-header>
          <ion-note>hi@ionicframework.com</ion-note> -->

          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item routerDirection="root" [routerLink]="[p.url]"  detail="false" routerLinkActive="selected">
              <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item routerDirection="root" (click)="logout()"  detail="false" routerLinkActive="selected">
              <ion-icon slot="start" name="log-out-outline"></ion-icon>
              <ion-label>logout</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <!-- <ion-list id="labels-list">
          <ion-list-header>Labels</ion-list-header>

          <ion-item *ngFor="let label of labels" lines="none">
            <ion-icon slot="start" ios="bookmark-outline" md="bookmark-sharp"></ion-icon>
            <ion-label>{{ label }}</ion-label>
          </ion-item>
        </ion-list> -->
      </ion-content>
      <!-- <ion-item >
        <ion-label style="margin-inline-start: 20px;">Light &nbsp;<ion-toggle (ionChange)="themeMode($event)" style="vertical-align: middle;"></ion-toggle>&nbsp; Dark</ion-label>
      </ion-item> -->
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
