import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Dashboard', url: '/analytics', icon: 'home' },
    { title: 'Bilty', url: '/bilty', icon: 'document' },
    {
      title: 'Invoice',
      url: '/invoice',
      icon: 'document',
    },
    // { title: 'Driver', url: '/driver', icon: 'home' },
    // { title: 'Vehicle', url: '/vehicle', icon: 'home' },
    // { title: 'Consignor', url: '/consignor', icon: 'home' },
    // { title: 'Consignee', url: '/consignee', icon: 'home' },
    { title: 'Subscription', url: '/subscription', icon: 'card' },
    { title: 'Settings', url: '/settings', icon: 'settings' },
    // { title: 'Plans', url: '/subscription', icon: 'settings' },
  ];

  constructor(private router: Router, private route: ActivatedRoute) {}
  ngOnInit() {
    var usertype = localStorage.getItem('usertype');
    console.log(usertype);
    var token = localStorage.getItem('token');
    var id = localStorage.getItem('id');
    var EmailId = localStorage.getItem('EmailId');
  }

  logout() {
    var token = localStorage.getItem('token');
    var id = localStorage.getItem('id');
    var EmailId = localStorage.getItem('EmailId');
    console.log(token);
    localStorage.clear();
    this.router.navigate(['/login']);
    console.log(token);
  }

  themeMode(e) {
    console.log(e);
    if (e.detail.checked) {
      document.body.setAttribute('color-theme', 'dark');
    } else {
      document.body.setAttribute('color-theme', 'light');
    }
  }
}
