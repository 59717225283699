import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';

// import {MatProgressBarModule} from '@angular/material/progress-bar';
// // import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import {MatNativeDateModule} from '@angular/material/core';

@NgModule({
    declarations: [AppComponent],
    imports: [
        FormsModule,
        // SignaturePadModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
    ],
    providers: [
        HttpClient,
        ToastController,
        LoadingController,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
